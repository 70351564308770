<template>
  <div class="box">
    <el-form :model="ruleForm"
             ref="ruleForm"
             label-width="100px">
      <el-form-item label="名称"
                    prop="name">
        <el-col :span="23">
          <el-input v-model="ruleForm.name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="备注"
                    prop="remarks">
        <el-col :span="23">
        <el-input type="textarea"
                  :rows="2"
                  v-model="ruleForm.remarks"></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <div class="transfer">
      <vxe-table border
                 class="transfer-item"
                 ref="xTable1"
                 row-id="code"
                 show-overflow
                 :data="leftData"
                 @checkbox-all="selectAllEvent"
                 @checkbox-change="selectChangeEvent">
        <vxe-table-column type="checkbox"
                          width="60"></vxe-table-column>
        <vxe-table-column field="code"
                          title="合同要素编号"></vxe-table-column>
        <vxe-table-column field="name"
                          title="合同要素名称"></vxe-table-column>
        <vxe-table-column field="remarks"
                          title="合同要素备注"></vxe-table-column>
      </vxe-table>
      <div class="button-group">
        <el-button type="primary"
                   class="arrow-btn"
                   @click="toRight"
                   icon="el-icon-arrow-right"></el-button>
        <el-button type="primary"
                   class="arrow-btn"
                   @click="toLeft"
                   icon="el-icon-arrow-left"></el-button>
      </div>
      <vxe-table border
                 class="transfer-item"
                 ref="xTable2"
                 row-id="code"
                 show-overflow
                 show-header-overflow
                 :data="rigftData"
                 :edit-config="{trigger: 'click', mode: 'cell'}"
                 @checkbox-all="selectAllEvent"
                 @checkbox-change="selectChangeEvent">
        <vxe-table-column type="checkbox"
                          width="60"></vxe-table-column>
        <vxe-table-column field="code"
                          title="合同要素编号"></vxe-table-column>
        <vxe-table-column field="name"
                          title="合同要素名称"></vxe-table-column>
        <vxe-table-column field="remarks"
                          title="合同要素备注"></vxe-table-column>
        <vxe-table-column field="tupleIndex"
                          title="顺序"
                          :edit-render="{name: '$input',props:{type:'number'},events:{change : changeSortNum}}"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button icon="el-icon-check"
                   type="primary"
                   @click="onOk">确定</el-button>
        <el-button icon="el-icon-check"
                   type="primary"
                   @click="onPreview">预览</el-button>
        <el-button icon="el-icon-close"
                   type="danger"
                   @click="onClose">取消</el-button>
      </div>
      <Modal :modalConfig.sync="modalConfig"
             :visible.sync="modalConfig.visible">
        <Form :formPreview="formPreview" @onClose="closePreview"/>
      </Modal>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import Modal from '../../../../../../components/modal';
import Form from './form.vue';

export default {
  data() {
    return {
      ruleForm: {
        name: '',
        remarks: '',
      },
      leftData: [],
      rigftData: [],
      modalConfig: {},
      formPreview: null,
      defaultSelecteRows: [],
    };
  },
  props: {
    formConfig: Object,
  },
  components: {
    Form,
    Modal,
  },
  mounted() {
    this.getList();
  },
  methods: {
    getData() {
      const params = {
        templateCode: this.formConfig.code,
      };
      request
        .get('/dms/m/saleContract/findTemplateDetailsByCode', params)
        .then((res) => {
          if (res.success) {
            this.ruleForm = {
              ...res.result,
            };
            const records = [];
            // console.log(res.result.tuples);

            res.result.tuples.forEach((v) => {
              this.leftData.forEach((a) => {
                const rowData = a;
                if (a.code === v.code) {
                  rowData.tupleIndex = v.tupleIndex;
                  records.push(rowData);
                }
                return rowData;
              });
            });

            this.$refs.xTable1.setCheckboxRow(records, true);
            const leftData = this.$refs.xTable1.getCheckboxRecords();
            leftData.sort((a, b) => a.tupleIndex - b.tupleIndex);
            this.rigftData = leftData;
          }
        });
    },
    getList() {
      const params = {
        pageSize: 9999,
        pageNum: 1,
      };
      request
        .post('/dms/m/saleContract/findTuplePageByConditions', params)
        .then((res) => {
          if (res.success) {
            this.leftData = res.result.data;

            if (this.formConfig.code) {
              this.getData();
            }
          }
        });
    },
    selectAllEvent({ checked, records }) {
      // console.log(checked ? '所有勾选事件' : '所有取消事件', records);
    },
    selectChangeEvent({ checked, records }) {
      // console.log(checked ? '勾选事件' : '取消事件', records);
    },
    toRight() {
      const leftData = this.$refs.xTable1.getCheckboxRecords();
      leftData.map((v, k) => {
        const rowData = v;
        if (!rowData.tupleIndex || rowData.tupleIndex === 100) {
          rowData.tupleIndex = k + 1;
        }
        return rowData;
      });
      leftData.sort((a, b) => a.tupleIndex - b.tupleIndex);
      this.rigftData = leftData;
    },
    toLeft() {
      this.$refs.xTable1.setCheckboxRow(
        this.$refs.xTable2.getCheckboxRecords(),
      );
      this.rigftData = this.$refs.xTable1.getCheckboxRecords();
    },
    changeSortNum(val) {
      // console.log(this.$refs.xTable2.getTableData());
      const rigftData = this.$refs.xTable2.getTableData().tableData;
      rigftData.sort((a, b) => a.tupleIndex - b.tupleIndex);
      this.rigftData = rigftData;
      console.log(rigftData);
    },
    onOk() {
      let path = '/dms/m/saleContract/addTemplate';
      if (this.formConfig.code) {
        path = '/dms/m/saleContract/editTemplate';
      }
      const params = {
        ...this.ruleForm,
        tuples: this.rigftData,
      };
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$message.success('创建成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
    onPreview() {
      this.formPreview = this.rigftData;
      this.openFull();
    },
    onClose() {
      this.$emit('onClose');
    },
    // 全屏打开
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    closePreview() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: false, type: 'Full' },
      };
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.transfer {
  display: flex;
  align-items: center;
  flex: 1;
}
.transfer-item {
  flex: 1;
  height: 100%;
}
.button-group {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  // justify-content: space-around;
}
.arrow-btn {
  margin: 20px 0;
  margin-left: 0 !important;
}
</style>
