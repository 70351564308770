var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { model: _vm.ruleForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c(
                "el-col",
                { attrs: { span: 23 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remarks" } },
            [
              _c(
                "el-col",
                { attrs: { span: 23 } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "transfer" },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable1",
              staticClass: "transfer-item",
              attrs: {
                border: "",
                "row-id": "code",
                "show-overflow": "",
                data: _vm.leftData,
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectChangeEvent,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "code", title: "合同要素编号" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "name", title: "合同要素名称" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "remarks", title: "合同要素备注" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-group" },
            [
              _c("el-button", {
                staticClass: "arrow-btn",
                attrs: { type: "primary", icon: "el-icon-arrow-right" },
                on: { click: _vm.toRight },
              }),
              _c("el-button", {
                staticClass: "arrow-btn",
                attrs: { type: "primary", icon: "el-icon-arrow-left" },
                on: { click: _vm.toLeft },
              }),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable2",
              staticClass: "transfer-item",
              attrs: {
                border: "",
                "row-id": "code",
                "show-overflow": "",
                "show-header-overflow": "",
                data: _vm.rigftData,
                "edit-config": { trigger: "click", mode: "cell" },
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectChangeEvent,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "code", title: "合同要素编号" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "name", title: "合同要素名称" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "remarks", title: "合同要素备注" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "tupleIndex",
                  title: "顺序",
                  "edit-render": {
                    name: "$input",
                    props: { type: "number" },
                    events: { change: _vm.changeSortNum },
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "primary" },
                  on: { click: _vm.onOk },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "primary" },
                  on: { click: _vm.onPreview },
                },
                [_vm._v("预览")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.onClose },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalConfig: _vm.modalConfig,
                visible: _vm.modalConfig.visible,
              },
              on: {
                "update:modalConfig": function ($event) {
                  _vm.modalConfig = $event
                },
                "update:modal-config": function ($event) {
                  _vm.modalConfig = $event
                },
                "update:visible": function ($event) {
                  return _vm.$set(_vm.modalConfig, "visible", $event)
                },
              },
            },
            [
              _c("Form", {
                attrs: { formPreview: _vm.formPreview },
                on: { onClose: _vm.closePreview },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }